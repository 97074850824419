import React, { useState } from "react";
import styles from "../styles/Components/header.module.sass";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Menu } from "antd";
import PoolData from "../pages/Auth/AWSConfig/PoolData";
import { setLogout } from "../redux/main/action/main";
import { useNavigate } from "react-router-dom";
import Avatar from "react-avatar";
import MenuItem from "./header/MenuItem";
import InsightHelp from "./help/InsightHelp";
import AIHelp from "./help/AIHelp";
import AlertHelp from "./help/AlertHelp";
import { IoIosArrowDown } from "react-icons/io";
import bot from "../asset/Icons/Profile-menu/robot (2).png";
import integration from "../asset/Icons/Profile-menu/integration (4).png";
import insights from "../asset/Icons/Profile-menu/insights.png";
import testing from "../asset/Icons/Profile-menu/testing (1).png";
import logout from "../asset/Icons/Profile-menu/logout (1).png";
import help from "../asset/Icons/Profile-menu/help-center (1).png";
import setting from "../asset/Icons/Profile-menu/settings (3).png";
import aiIcon from "../asset/Icons/icon-15.svg";
import telegram from "../asset/Icons/Profile-menu/miniApp.png";
import HeaderNavigationMenu from "./header/HeaderNavigationMenu";

const menuData = [
  {
    label: "My AI Bot",
    description: "Personal AI bot management",
    icon: bot,
  },
  {
    label: "My Integrated Exchanges",
    description: "Centralize crypto exchange monitoring",
    icon: integration,
  },
  {
    label: "My AI Signals",
    description: "Your personal trading advisor, powered by AI",
    icon: aiIcon,
  },
  {
    label: "My Insights Sessions",
    description: "Check insights sessions",
    icon: insights,
  },
  {
    label: "My Backtests",
    description: "Backtest trading strategies",
    icon: testing,
  },
  {
    label: "Telegram Mini App",
    description: "Access and manage your Telegram bot",
    icon: telegram,
  },
  {
    label: "Settings",
    description: "Customize platform and account",
    icon: setting,
  },
  {
    label: "Help",
    description: "Receive help and guidance",
    icon: help,
  },
  {
    label: "Logout",
    description: "Securely log out account",
    icon: logout,
  },
];

const Header = () => {
  const dispatch = useDispatch();
  const {
    main: { userData },
    profile: { profileData },
    alertSystem: { telegramBotToken },
  } = useSelector((state) => state);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openAi, setOpenAi] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const telegramLink = telegramBotToken
    ? `https://t.me/cryptocrispy_bot?start=${telegramBotToken}`
    : "https://t.me/cryptocrispy_bot";
  const handleAiOk = () => setOpenAi(false);
  const handleAiCancel = () => setOpenAi(false);
  const handleAlertOk = () => setOpenAlert(false);
  const handleAlertCancel = () => setOpenAlert(false);

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  const handleMenuClick = (e) => {
    // eslint-disable-next-line default-case
    switch (e.key) {
      case "0":
        navigate("/bot-automation");
        break;
      case "1":
        navigate("/integration");
        break;
      case "2":
        navigate("/ai-assistant/assistant");
        // navigate("/insight");
        break;
      case "3":
        navigate("/insight");
        break;
      case "4":
        // navigate("/trade-simulator");
        // navigate("/ai-assistant/assistant");
        navigate("/bot-automation/backtest");
        break;
      case "5":
        window.open(telegramLink, "_blank");
        break;
      case "6":
        navigate("/profile/info");
        break;
      case "7":
        navigate("/faq/all");
        break;
      case "8":
        dispatch(setLogout());
        const user = PoolData.getCurrentUser();
        user?.signOut();
        break;
    }
  };
  const menu = (
    <Menu
      className={styles.MenuClassName}
      style={{ left: "0" }}
      onClick={handleMenuClick}
      items={menuData.map((item, index) => ({
        label: (
          <MenuItem
            img={item.icon}
            description={item.description}
            title={item.label}
          />
        ),
        key: `${index}`,
      }))}
    />
  );

  return (
    <>
      <div className={styles.headerContainer}>
        <HeaderNavigationMenu />
        <div className={styles.profileContainer}>
          <span>
            {profileData?.avatar_url ? (
              <img
                className={styles.profileImg}
                src={profileData?.avatar_url}
                alt="profile image"
              />
            ) : (
              <Avatar
                name={userData?.["custom:custom_username"]}
                size="100%"
                round={true}
              />
            )}
          </span>
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            className={styles.dropDown}
          >
            <a
              onClick={(e) => e.preventDefault()}
              title={userData?.["custom:custom_username"]}
            >
              <span className={styles.userName}>
                {userData?.["custom:custom_username"] &&
                userData?.["custom:custom_username"].length > 10
                  ? userData?.["custom:custom_username"].slice(0, 10) + "..."
                  : userData?.["custom:custom_username"]}
              </span>
              <IoIosArrowDown />
            </a>
          </Dropdown>
        </div>
      </div>
      <InsightHelp
        open={open}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <AIHelp
        open={openAi}
        handleOk={handleAiOk}
        handleCancel={handleAiCancel}
      />
      <AlertHelp
        open={openAlert}
        handleOk={handleAlertOk}
        handleCancel={handleAlertCancel}
      />
    </>
  );
};

export default Header;
